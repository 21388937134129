import '@/styles/globals.scss'
import { LaunchDarklyProvider } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.context'
import { PromotionsProvider } from '@saatva-bits/pattern-library.modules.promotions'
import { FaqProvider } from '@saatva-bits/pattern-library.modules.faq'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import { SharedCartServiceProvider } from '@saatva-bits/pattern-library.modules.cart'
import { PagePropsProvider } from '@/contexts/pageProps'
import { PrismicPreview } from '@prismicio/next'
import initLogger from '@/utils/initLogger'

const repositoryName = 'saatva-site-content'

initLogger()

export default function App({ Component, pageProps }) {
    if (pageProps.skipBaseProviders) {
        // static pages
        return <Component {...pageProps} />
    } else {
        // server side pages
        return (
            /* Wrap with any providers all pages need */
            <LaunchDarklyProvider contextData={pageProps.launchDarklyContextData}>
                <SharedCartServiceProvider
                    apiRoute={process.env.NEXT_PUBLIC_CART_URL}
                    initialCartCount={pageProps.cartData?.cartQuantity}
                >
                    <PromotionsProvider contextData={pageProps.promotionsContextData}>
                        <FaqProvider faqList={pageProps.faqList} specList={pageProps.specList}>
                            <ErrorBoundary {...pageProps}>
                                <PagePropsProvider pageProps={pageProps}>
                                    <Component {...pageProps} />
                                    <PrismicPreview repositoryName={repositoryName} />
                                </PagePropsProvider>
                            </ErrorBoundary>
                        </FaqProvider>
                    </PromotionsProvider>
                </SharedCartServiceProvider>
            </LaunchDarklyProvider>
        )
    }
}
