/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'

import { Button } from '@saatva-bits/pattern-library.components.button'

import Layout from '@/layouts/Default'

import styles from './styles.module.scss'

const Heading = ({ regions }) => {
    return (
        <div className="container">
            <div className={classNames('row', styles.hero)}>
                <div className="col col--xs-12">
                    <h1 className={classNames('t-heading1', styles.heading)}>
                        Saatva Viewing Rooms
                    </h1>
                    <div className={styles.subheading}>
                        Experience Saatva comfort in real life in an inviting,
                        pressure-free environment before enjoying your{' '}
                        <a href="https://www.saatva.com/trial">365-night home trial</a>.
                    </div>
                    {regions && (
                        <div className={styles.regionButtons}>
                            {regions.map(region => (
                                <Button
                                    key={region}
                                    className={classNames(styles.regionButton)}
                                    onClick={() => {
                                        const section = document.getElementById(`${region.toLowerCase()}-viewing-rooms-section`)
                                        section?.scrollIntoView({ behavior: 'smooth' })
                                    }}
                                >
                                    {capitalize(region)}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const LocationsByRegion = ({ locations, regions }) => {
    return (
        <div className={styles.locationsByRegion}>
            <div className="container">
                {regions?.map((region, index) => {
                    const id = `${region.toLowerCase()}-viewing-rooms-section`
                    const title = capitalize(region)
                    const regionLocations = locations.filter(({ c_region }) => c_region === region)

                    return regionLocations.length >= 1 && (
                        <section key={id} id={id}>
                            <h3 className={classNames('t-heading2', styles.heading)}>
                                <span className="u-paddingHorizontal--md">{title}</span>
                            </h3>
                            <div className={classNames('row', styles.locationsRow)}>
                                {regionLocations.map(location => {
                                    return (
                                        <div
                                            key={location.address.postalCode}
                                            className={classNames('col col--xs-12 col--sm-8 col--md-6 col--lg-4', styles.locationTileContainer)}
                                        >
                                            <a href={`/locations/${location.slug}`} className={styles.locationTile}>
                                                <div className={styles.locationTitle}>
                                                    {location.c_customName}
                                                </div>
                                                {location.c_hubPhoto && (
                                                    <img src={location.c_hubPhoto.url} className={styles.image} alt={`location hub photo ${index}`} />
                                                )}
                                                <div className={styles.neighborhood}>
                                                    {`Saatva ${location.c_neighborhood}`}
                                                </div>
                                                <div className={styles.address}>
                                                    <div>{location.address.line1}</div>
                                                    <div>{`${location.address.city}, ${location.address.region} ${location.address.postalCode}`}</div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    )
                })}
            </div>
        </div>
    )
}

export default function LocationsView(props) {
    return (
        <Layout {...props}>
            <Heading regions={props.regions} />
            <LocationsByRegion locations={props.locations} regions={props.regions} />
        </Layout>
    )
}
