import { useState, useEffect, useCallback } from 'react'

import { useSharedCartService } from '@saatva-bits/pattern-library.modules.cart'
import { MastheadSiteNav } from '@saatva-bits/pattern-library.modules.navigation'
import { CartFlyout } from '@saatva-bits/pattern-library.modules.cart-flyout'
import { SearchBar } from '@saatva-bits/pattern-library.modules.search'
import { UserAccountPopup } from '@saatva-bits/pattern-library.components.user-account-popup'

import { Cookies } from '@saatva-bits/pattern-library.utils.cookies'
import { open as openChat, useChatZindex } from '@saatva-bits/pattern-library.utils.chat'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import { fetchApi } from '@/clientRequests/api'
import phoneNumbers from '@/temp-configs/contact-phones'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL
const ALGOLIA_SEARCH_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX
const ALGOLIA_ARTICLE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_ARTICLE_INDEX
const SEARCH_INDICES = [ALGOLIA_SEARCH_INDEX, ALGOLIA_ARTICLE_INDEX]

export default function Nav({ navigationData, viewingRoomData, isSearchPage }) {
    const cartContext = useSharedCartService()
    const [searchOpen, setSearchOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const { isV1: isDesktopExpandedRedesignTest } = useExperiment('EXP.DT_EXPANDED_NAV_NEW.DIS-139')
    const { isV1: HIDE_CHECKOUT_BUTTON } = useExperiment('EXP.HIDE_CHECKOUT_BUTTON_IN_CART_FLYOUT.CONV-317')
    const { isV0: phoneNumberTest } = useExperiment('EXP.NEED_HELP.EX-391')

    const toggleSearchBar = () => setSearchOpen(prev => !prev)

    /* If hideVrIcon is true, icon will be completely hidden, allowing the LD flag to override the display completely */
    const hideVrIcon = viewingRoomData.hideVrIcon
    const [viewingRoomUrl, setViewingRoomUrl] = useState(viewingRoomData.url)

    const updateVrIcon = async () => {
        /* Hit designated (shared) API for viewing room response */
        const { url } = await fetchApi('viewing-room')
        /* Update viewing room url to display icon if valid location */
        setViewingRoomUrl(url)
    }

    const searchAnalyticsCallback = useCallback(() => {
        const gaCookieValue = Cookies.get('_ga')
        // Removes prefix and converts periods to underscores
        const gaUserIdFromCookie = gaCookieValue?.split('.')?.slice(-2)?.join('_')
        if (typeof window !== 'undefined') {
            window.dataLayer.push({
                event: 'Hits Viewed',
                algoliaUserToken: gaUserIdFromCookie
            })
        }
    }, [])

    useChatZindex(drawerOpen)

    useEffect(() => {
        /* Only check/update VR location if there isn't a vrKey value, indicating
        the cookie hasn't already been set. */
        if (typeof window !== 'undefined' && !viewingRoomData.vrKey && ('url' in viewingRoomData)) {
            updateVrIcon().catch(error => console.log(error))
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const saatvaDomain = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}`

    const searchProps = {
        isSearchOpen: searchOpen,
        isMenuOpen: menuOpen,
        isSearchPage: isSearchPage,
        onClose: toggleSearchBar,
        searchIndices: SEARCH_INDICES,
        analyticsCallback: searchAnalyticsCallback,
        serviceOptions: {
            SAATVA_DOMAIN: saatvaDomain,
            IMGIX_URL,
            API_ROUTES: {
                content: process.env.NEXT_PUBLIC_CONTENT_URL
            }
        }
    }

    const userAccountPopup = <UserAccountPopup saatvaDomain={saatvaDomain} />

    const navProps = {
        viewingRoomUrl: viewingRoomUrl,
        hideVrIcon: hideVrIcon,
        handleChat: openChat,
        topLevelItems: navigationData,
        imgixDomain: IMGIX_URL,
        onClickSearchButton: toggleSearchBar,
        onMenuToggle: setMenuOpen,
        onDrawerToggle: setDrawerOpen,
        hideOrderTracker: false,
        hideCart: false,
        hideSearchIcon: isSearchPage,
        cartContext,
        // cartFlyoutDeps: { CartFlyout },
        cartFlyoutDeps: {
            CartFlyout: (props) => <CartFlyout {...props} hideCheckoutBtn={HIDE_CHECKOUT_BUTTON} />
        },
        userAccountPopup,
        contactPhoneNumber: phoneNumberTest ? phoneNumbers.phoneNumberV0 : phoneNumbers.phoneNumberV1,
        isDesktopExpandedRedesignTest
    }

    return (
        <header className={'headerClasses'} id="sticky-header">
            <div>
                <MastheadSiteNav {...navProps}>
                    <SearchBar {...searchProps} />
                </MastheadSiteNav>
            </div>
        </header>
    )
}
